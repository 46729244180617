import { lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'

const Home = lazy(() => import('./routes/Home'))
const Donate = lazy(() => import('./routes/Donate'))
const About = lazy(() => import('./routes/About'))
const Gallery = lazy(() => import('./routes/Gallery'))

const App = () => {
	return (
		<Suspense fallback={<></>}>
			<Routes>
				<Route path='/' element={<Home />} />
				<Route path='/donate' element={<Donate />} />
				<Route path='/about' element={<About />} />
				<Route path='/gallery' element={<Gallery />} />

				<Route path='*' element={<Home />} />
			</Routes>
		</Suspense>
	)
}

export default App